import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import UseUsers from "../../../services/useUsers";
import { UpdateSchema } from "../../../zodSchema/UpdateSchema";
import { XIcon } from "lucide-react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { OptionsOrGroups, GroupBase } from "react-select";
import useInstance from "./../../../services/instance";
import makeAnimated from "react-select/animated";
import _ from "lodash";
import UseUniversities from "./../../../services/useUniversities";
import { Popover, PopoverContent, PopoverTrigger } from "./../../ui/poopover";
import YearSeasonSelector from "../YearSeasonSelector";
import useAuth from "./../../../services/useAuth";

type profileUpdateSchema = z.infer<typeof UpdateSchema>;

type UniversityOption = {
  value: string;
  label: string;
};

interface UserStatus {
  id: number;
  name: string;
}

interface Location {
  city: string;
  state: string;
}

interface EditProfileModalProps {
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  profile?: profileUpdateSchema | null;
}

const EditProfileModal: React.FC<EditProfileModalProps> = ({
  isEditing,
  setIsEditing,
  profile,
}) => {
  const { getUserStatuses, updateProfile } = UseUsers();
  const queryClient = useQueryClient();
  const animatedComponents = makeAnimated();
  const { instance: api } = useInstance();

  const [universityName, setUniversityName] = useState<string>(
    profile?.university_name || ""
  );
  const [course, SetCourse] = useState<string>(profile?.course_name || "");
  const [userStatuses, setUserStatuses] = useState<UserStatus[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<number>(
    profile?.user_status_id || 1
  );
  const [campus_location, setCampusLocation] = useState<string>();
  const getSeasonFromDate = (dateStr?: string) => {
    if (!dateStr) return { season: "Spring", year: new Date().getFullYear().toString() };
    
    const date = new Date(dateStr);
    const month = date.getMonth();
    const season = month <= 2 ? "Spring" :
                  month <= 5 ? "Summer" :
                  month <= 8 ? "Fall" : 
                  "Winter";
    
    return {
      season,
      year: date.getFullYear().toString()
    };
  };

  const { season: initialSeason, year: initialYear } = getSeasonFromDate(profile?.course_start_date || "");

  const [home, setHome] = useState<Location>();
  const [open, setOpen] = useState(false);
  const [year, selectYear] = useState<string>(initialYear);
  const [season, selectSeasons] = useState<string>(initialSeason);



  const { getUniversitiesById } = UseUniversities();
  const { getLocations } = useAuth();
  const seasons = [
    { name: "Spring", icon: "🌸" },
    { name: "Summer", icon: "☀️" },
    { name: "Fall", icon: "🍂" },
    { name: "Winter", icon: "❄️" },
  ];

  useEffect(() => {
    const fetchUserStatuses = async () => {
      try {
        const response = await getUserStatuses();
        setUserStatuses(response.data);
      } catch (error) {
        toast.error("Failed to fetch user statuses");
      }
    };
    fetchUserStatuses();
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<profileUpdateSchema>({
    resolver: zodResolver(UpdateSchema),
  });

  React.useEffect(() => {
    if (profile) {
      setValue("campus_location", profile.campus_location);
      setValue("course_name", profile.course_name);
      setValue("course_start_date", profile.course_start_date);
      setValue("current_location", profile.current_location);
      setValue("user_status_id", profile.user_status_id || 1);
    }
  }, [profile, setValue]);

  const onSubmit: SubmitHandler<profileUpdateSchema> = async (data) => {
    const obj: profileUpdateSchema = {
      user_status_id: selectedStatus,
      university_name: selectedStatus === 1 ? "" : universityName || "",
      campus_location: data.campus_location || campus_location || "",
      course_name: course || "",
      course_start_date:
        `${season}, ${year}` || "I am currently shortlisting universities",
      current_location: data.current_location || "",
      city_name: data.current_location || "",
    };

    const filteredObj = Object.fromEntries(
      Object.entries(obj).filter(
        ([key, value]) =>
          key === "city_name" ||
          key === "user_status_id" ||
          key === "university_name" ||
          (value != null && value.toString().trim() !== "")
      )
    );

    updateMutation.mutate(filteredObj);
  };

  const updateMutation = useMutation({
    mutationFn: updateProfile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      toast.success("Update Successful");
      setIsEditing(false);
    },
    onError: () => {
      toast.error("Update failed");
    },
  });

  const debouncedFetchUniversities = _.debounce(
    async (
      inputValue: string,
      callback: (
        options: OptionsOrGroups<UniversityOption, GroupBase<UniversityOption>>
      ) => void
    ) => {
      try {
        const response = await api.get(`/universities/`, {
          params: { q: inputValue, limit: 40 },
        });
        const options = response.data.map((university: any) => ({
          value: university.value,
          label: university.label,
        }));
        callback(options);
      } catch (error) {
        callback([]);
      }
    },
    500
  );

  const debouncedFetchCourses = _.debounce(
    async (
      inputValue: string,
      callback: (
        options: OptionsOrGroups<UniversityOption, GroupBase<UniversityOption>>
      ) => void
    ) => {
      try {
        const response = await api.get(`/courses/`, {
          params: { q: inputValue, limit: 40 },
        });
        const options = response.data.map((course: any) => ({
          value: course.value,
          label: course.label,
        }));
        callback(options);
      } catch (error) {
        callback([]);
      }
    },
    500
  );

  const fetchUniversities = (
    inputValue: string,
    callback: (
      options: OptionsOrGroups<UniversityOption, GroupBase<UniversityOption>>
    ) => void
  ) => {
    debouncedFetchUniversities(inputValue, callback);
  };

  const fetchCourses = (
    inputValue: string,
    callback: (
      options: OptionsOrGroups<UniversityOption, GroupBase<UniversityOption>>
    ) => void
  ) => {
    debouncedFetchCourses(inputValue, callback);
  };

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f0f0f0" : "#fff",
      color: state.isFocused ? "#000" : "#333",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#333",
    }),
  };

  const getCityFromUniversityMutation = useMutation({
    mutationFn: getUniversitiesById,
    onSuccess: async (data) => {
      setCampusLocation(data.campus_location);
    },
  });

  const handleUniversitySelectChange = (setter: any, selectedOption: any) => {
    const value = selectedOption ? selectedOption.label : "";
    setter(value);
    getCityFromUniversityMutation.mutate(selectedOption.value);
  };

  const handleCourseSelectChange = (setter: any, selectedOption: any) => {
    const value = selectedOption ? selectedOption.label : "";
    setter(value);
  };

  const getLocationMutation = useMutation({
    mutationFn: getLocations,
    onSuccess: async (data) => {
      setHome(data);
    },
  });

  const getLocation = () => {
    if (!navigator.geolocation) {
      toast.error("Geolocation is not supported by this browser.");
      return;
    }

    const getPosition = () => {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    };

    getPosition()
      .then((position: any) => {
        const location = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        getLocationMutation.mutate(location);
      })
      .catch((error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            toast.error("Location permission denied by user.");
            break;
        }
      });
  };

  if (!isEditing) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 z-50 backdrop-blur-sm">
      <div className="relative bg-unimaytLight p-8 rounded-lg w-full max-w-lg md:w-100 md:h-auto h-full flex justify-center items-center flex-col lg:pt-12 lg:-mb-4">
        <button
          onClick={() => setIsEditing(false)}
          className="absolute top-6 right-4 lg:right-4 text-light text-paragraph font-heading"
        >
          <XIcon />
        </button>

        <h2 className="mb-4 text-center text-smheading font-heading text-light">
          Edit Profile
        </h2>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-1">
          {/* User Status Selection */}
          <label className="block text-subparagraph font-semibold text-light">
            User Status
            <select
              className="w-full mt-1 border p-2 rounded-lg text-Button outline-none"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(Number(e.target.value))}
            >
              {userStatuses.map((status) => (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              ))}
            </select>
          </label>

          {/* University Name - Only show if not "Shortlisting Universities" */}
          {selectedStatus !== 1 && (
            <label className="block text-subparagraph font-semibold text-light relative">
              University Name
              <div className="relative">
                <AsyncCreatableSelect
                  cacheOptions
                  components={animatedComponents}
                  onChange={(data) => {
                    handleUniversitySelectChange(setUniversityName, data);
                  }}
                  defaultOptions
                  loadOptions={fetchUniversities}
                  styles={customStyles}
                  placeholder={
                    profile?.university_name || "Enter your university name"
                  }
                  formatCreateLabel={(inputValue) => `other "${inputValue}"`}
                  isValidNewOption={(inputValue, selectOptions) => {
                    return (
                      inputValue.trim() !== "" &&
                      !selectOptions.some(
                        (option) =>
                          option.label.toLowerCase() === inputValue.toLowerCase()
                      )
                    );
                  }}
                />
              </div>
              {errors.university_name && (
                <p className="text-red-500">{errors.university_name.message}</p>
              )}
            </label>
          )}

          <label className="block text-subparagraph font-semibold text-light">
            Campus Location
            <input
              type="text"
              {...register("campus_location")}
              value={campus_location || ""}
              disabled={
                getCityFromUniversityMutation.isPending &&
                getCityFromUniversityMutation.isSuccess
                  ? true
                  : getCityFromUniversityMutation.isError
                    ? false
                    : true
              }
              className="w-full mt-1 border p-2 rounded-lg text-Button outline-none"
              onChange={(e) => {
                setCampusLocation(e.target.value);
              }}
              placeholder={
                profile?.campus_location || "Please type in your campus city"
              }
            />
            {errors.campus_location && (
              <p className="text-red-500">{errors.campus_location.message}</p>
            )}
          </label>

          <label className="block text-subparagraph font-semibold text-light">
            Which course are you enrolled into
            <div className="relative">
              <AsyncCreatableSelect
                cacheOptions
                components={animatedComponents}
                onChange={(data) => {
                  handleCourseSelectChange(SetCourse, data);
                }}
                defaultOptions
                loadOptions={fetchCourses}
                styles={customStyles}
                placeholder={
                  profile?.course_name || "Enter your course name"
                }
                formatCreateLabel={(inputValue) => `other "${inputValue}"`}
                isValidNewOption={(inputValue, selectOptions) => {
                  return (
                    inputValue.trim() !== "" &&
                    !selectOptions.some(
                      (option) =>
                        option.label.toLowerCase() === inputValue.toLowerCase()
                    )
                  );
                }}
              />
            </div>
            {errors.course_name && (
              <p className="text-red-500">{errors.course_name.message}</p>
            )}
          </label>

          <label className="block text-subparagraph font-semibold text-light">
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger className="w-full border my-1.5 mt-2 flex px-2 items-center justify-start py-2 rounded-md bg-white text-unimaytLight">
                {season && year
                  ? `${season}, ${year}`
                  : "When does the course start"}
              </PopoverTrigger>
              <PopoverContent>
                <YearSeasonSelector
                  selectSeasons={selectSeasons}
                  season={season}
                  selectYear={selectYear}
                  year={year}
                  seasons={seasons}
                  onOpenChange={setOpen}
                />
              </PopoverContent>
            </Popover>
            {errors.course_start_date && (
              <p className="text-red-500">
                {errors.course_start_date?.message}
              </p>
            )}
          </label>

          <label className="block text-subparagraph font-semibold text-light pb-4">
            Home Location
            <div className="flex items-center justify-between pz-3">
              <input
                type="text"
                {...register("current_location")}
                value={
                  getLocationMutation.isSuccess && home
                    ? `${home.city}, ${home.state}`
                    : ""
                }
                defaultValue={profile?.current_location}
                className="w-full mt-1 p-2 rounded-lg text-Button outline-none"
                placeholder={profile?.current_location || "City you call home"}
              />
              {getLocationMutation.isPending ? (
                <div className="w-10 flex py-2 items-center justify-center">
                  <img
                    src="/icons/ripples.svg"
                    alt="loading"
                    className="w-7 h-7"
                  />
                </div>
              ) : (
                <div
                  onClick={() => {
                    getLocation();
                  }}
                  className="cursor-pointer h-12 p-2 flex items-center justify-center"
                >
                  Find?
                </div>
              )}
            </div>
            {errors.current_location && (
              <p className="text-red-500">{errors.current_location.message}</p>
            )}
          </label>
          <input type="submit" className="btn" />
        </form>
      </div>
    </div>
  );
};

export default EditProfileModal;